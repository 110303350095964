import React, {Ref, useCallback, useEffect, useMemo} from "react";
import {Button, Flex, ScrollView, SelectField, Text} from "@aws-amplify/ui-react";
import {TableDoubleRow} from "./TableDoubleRow";
import {
    autoFillTable, calculateGamePrice, clearTable,
    FormTypes, lottoMaxNumber, lottoMaxStrongNumber,
    regularLottoData,
    regularLottoFormSize,
    regularLottoFormType,
    regularLottoMaximumRowsAllowed,
    regularLottoTables,
    setFormSize,
    setFormType, setMaxNumber, setMaxStrongNumber,
    regularLottoMultiLottery,
    setMultiLottery
} from "../../features/regularLotto/regularLottoSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {makeStyles} from "@material-ui/core/styles";
import {getSelectedRow, getShowKeyboard, setSelectedRowIndex} from "../../features/keyboard/keyboardSlice";

interface RegularLottoFormProps {
    elRef: Ref<HTMLDivElement>
}

const useStyle = makeStyles(({
    wrapper: {
        direction: 'rtl',
        justifyContent: 'center',
        '& .amplify-select__icon-wrapper': {
            right: 100,
        },
        '& .amplify-select': {
            borderRadius: 11,
            with: 400,
        }
    }
}))

export const RegularLottoForm: React.FC<RegularLottoFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const classes = useStyle();
    const formData = useAppSelector(regularLottoData);
    const tablesData = useAppSelector(regularLottoTables);
    const formSize = useAppSelector(regularLottoFormSize);
    const maximumNumAllowed = useAppSelector(regularLottoMaximumRowsAllowed);
    const showKeyboard = useAppSelector(getShowKeyboard);
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const formType = useAppSelector(regularLottoFormType);
    const maxSelectedNumbers = useAppSelector(lottoMaxNumber);
    const maxSelectedStrongNumbers = useAppSelector(lottoMaxStrongNumber);
    const multiLottery = useAppSelector(regularLottoMultiLottery);

    const filledRows = useMemo(() => tablesData.filter(row => row.numbers.length === maxSelectedNumbers && row.strong_number.length === maxSelectedStrongNumbers).length, [tablesData])

    useEffect(() => {
        if (filledRows) {
            if ((formType === FormTypes.REGULAR || formType === FormTypes.REGULAR_DOUBLE) && filledRows % 2 === 0) {
                dispatch(calculateGamePrice(formData))
            } else if (formType !== FormTypes.REGULAR && formType !== FormTypes.REGULAR_DOUBLE) {
                dispatch(calculateGamePrice(formData))
            }
        }
    }, [dispatch, filledRows, formType])


    const automaticFill = useCallback(() => {
        dispatch(autoFillTable({
            index: selectedRowIndex,
            numbersLength: maxSelectedNumbers,
            strongNumberLength: maxSelectedStrongNumbers,
        }))
    }, [dispatch, formSize, formType, maxSelectedNumbers, maxSelectedStrongNumbers, selectedRowIndex])


    const onChangeRowNum = useCallback((e: any) => {
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
        if ([FormTypes.REGULAR, FormTypes.REGULAR_DOUBLE].includes(formType)) {
            dispatch(setFormSize(Number(e.currentTarget.value)))
            dispatch(setSelectedRowIndex(0));
        } else if ([FormTypes.SYSTEM_LOTTO, FormTypes.SYSTEM_DOUBLE_LOTTO].includes(formType)) {
            dispatch(setMaxNumber(e.currentTarget.value));
        } else if ([FormTypes.STRONG_LOTTO, FormTypes.DOUBLE_STRONG_LOTTO].includes(formType)) {
            dispatch(setMaxStrongNumber(e.currentTarget.value));
        }
    }, [dispatch, formType])

    const onChangeFormType = useCallback((e: any) => {
        dispatch(setFormType(e.currentTarget.value))
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
    }, [dispatch])

    const options = useMemo(() => {
        if ([FormTypes.REGULAR, FormTypes.REGULAR_DOUBLE].includes(formType)) {
            const numOfOptions = Array.from(Array(maximumNumAllowed))
            return numOfOptions.map((_, index) => {
                return <option value={index + 1} key={index}>
                    {(index + 1) * 2} שורות
                </option>
            })
        } else if ([FormTypes.SYSTEM_LOTTO, FormTypes.SYSTEM_DOUBLE_LOTTO].includes(formType)) {
            const numOfOptions = ['8', '9', '10', '11', '12', '5=6']
            return numOfOptions.map((val) => {
                return <option value={val} key={val}>
                    שיטתי {val}
                </option>
            })
        } else if ([FormTypes.STRONG_LOTTO, FormTypes.DOUBLE_STRONG_LOTTO].includes(formType)) {
            const numOfOptions = ['4', '5', '6', '7']
            return numOfOptions.map((val) => {
                return <option value={val} key={val}>
                    {val} חזקים
                </option>
            })
        }
    }, [formType, maximumNumAllowed])

    const gameSubTypeOptions = useMemo(() => {
        if ([FormTypes.REGULAR, FormTypes.REGULAR_DOUBLE].includes(formType)) {
            return <>
                <option value={FormTypes.REGULAR} label="טופס רגיל"/>
                <option value={FormTypes.REGULAR_DOUBLE} label="טופס דאבל"/>
            </>
        } else if ([FormTypes.SYSTEM_LOTTO, FormTypes.SYSTEM_DOUBLE_LOTTO].includes(formType)) {
            return <>
                <option value={FormTypes.SYSTEM_LOTTO} label="טופס רגיל"/>
                <option value={FormTypes.SYSTEM_DOUBLE_LOTTO} label="טופס דאבל"/>
            </>
        } else if ([FormTypes.STRONG_LOTTO, FormTypes.DOUBLE_STRONG_LOTTO].includes(formType)) {
            return <>
                <option value={FormTypes.STRONG_LOTTO} label="טופס רגיל"/>
                <option value={FormTypes.DOUBLE_STRONG_LOTTO} label="טופס דאבל"/>
            </>
        }
    }, [formType])

    return (
        <Flex direction="column" gap="1rem">
            <Text>* בחרו את מספר השורות סוג הטופס ומספר ההגרלות-</Text>
            <Flex className={classes.wrapper}>
                <SelectField onChange={onChangeFormType} width={'100%'} label={false} fontWeight={'bold'}>
                    {gameSubTypeOptions}
                </SelectField>
                <SelectField width={'100%'} label={false} fontWeight={'bold'} onChange={onChangeRowNum}
                             defaultValue={'' + formSize}>
                    {options}
                </SelectField>
                <SelectField
                    label={false}
                    value={multiLottery.toString()}
                    onChange={(e) => dispatch(setMultiLottery(Number(e.target.value)))}
                    fontWeight={'bold'}
                >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="8">8</option>
                </SelectField>
            </Flex>
            <Button onClick={() => automaticFill()} backgroundColor={'#1E1E1E'} isFullWidth>מלא
                לוטומטיק</Button>
            <Text>*בחרו שורה וסמנו בעזרת המקלדת -</Text>
            <ScrollView ref={props.elRef} width="100%"
                        height={showKeyboard ? ['205px', '205px', '205px', "538px"] : 450}>
                {
                    Array.from(Array(formSize)).map((_, index) =>
                        <TableDoubleRow key={index} index={index}/>
                    )
                }
            </ScrollView>
        </Flex>
    )
}
