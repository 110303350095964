import React, {Ref, useCallback, useEffect, useMemo} from "react";
import {Button, Flex, ScrollView, SelectField, Text} from "@aws-amplify/ui-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {makeStyles} from "@material-ui/core/styles";
import {getSelectedRow, setSelectedRowIndex} from "../../features/keyboard/keyboardSlice";
import {ChanceRow} from "./ChanceRow";
import {
    autoFillTable,
    calculateGamePriceChance,
    chanceCards,
    chanceData,
    chanceFormSize,
    chanceFormType, ChanceFormTypes,
    chanceMaxNumber, chanceParticipantAmount, clearTable, setAmount, setMaxNumber,
    chanceMultiLottery,
    setMultiLottery,
} from "../../features/chance/chanceSlice";

interface RegularLottoFormProps {
    elRef: Ref<HTMLDivElement>
}

const useStyle = makeStyles(({
    wrapper: {
        direction: 'rtl',
        justifyContent: 'center',
        '& .amplify-select__icon-wrapper': {
            right: 140,
        },
        '& .amplify-select': {
            borderRadius: 11,
            with: 400,
        }
    }
}))

export const ChanceForm: React.FC<RegularLottoFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const classes = useStyle();
    const formData = useAppSelector(chanceData);
    const tablesData = useAppSelector(chanceCards);
    const formSize = useAppSelector(chanceFormSize);
    const selectedRowIndex = useAppSelector(getSelectedRow);
    const formType = useAppSelector(chanceFormType);
    const maxSelectedNumbers = useAppSelector(chanceMaxNumber);
    const participantAmount = useAppSelector(chanceParticipantAmount);
    const multiLottery = useAppSelector(chanceMultiLottery);

    const filledRows = useMemo(() => Object.values(tablesData).reduce((a: number, b: string[]) => a += +b.length, 0), [tablesData])

    useEffect(() => {
        if (filledRows) {
            dispatch(calculateGamePriceChance(formData))
        }
    }, [dispatch, filledRows, formType, participantAmount])


    const automaticFill = useCallback(() => {
        dispatch(autoFillTable({
            index: selectedRowIndex,
            numbersLength: maxSelectedNumbers,
        }))
    }, [dispatch, maxSelectedNumbers, selectedRowIndex])


    const onChangeRowNum = useCallback((e: any) => {
        dispatch(clearTable())
        dispatch(setSelectedRowIndex(0));
        if (formType === ChanceFormTypes.REGULAR_CHNACE || formType === ChanceFormTypes.CHANCE_SHITATI) {
            dispatch(setMaxNumber(Number(e.currentTarget.value)))
            dispatch(setSelectedRowIndex(0));
        }
    }, [dispatch, formType])

    const onChangeParticipantSum = useCallback((e: any) => {
        dispatch(setAmount(parseInt(e.currentTarget.value)))
    }, [dispatch])

    const participantOptions = useMemo(() => {
        const numOfOptions = formType === ChanceFormTypes.CHANCE_SHITATI ? [5, 10] : [5, 10, 25, 50, 70, 100, 250, 500];
        return numOfOptions.map((val) => {
            return <option value={val} key={val}>
                {val} ש״ח
            </option>
        })
    }, [formType])

    const formMaxSizeOptions = useMemo(() => {
        const numOfOptions = [1, 2, 3, 4];
        return numOfOptions.map((val) => {
            return <option value={val} key={val}>
                צ׳אנס {val}
            </option>
        })
    }, [])

    return (
        <Flex direction={'column'} paddingTop={[0, 0, 0, 40]} maxWidth={360} margin={'auto'}>
            <Text>* בחרו את סכום ההשקעה ומספר ההגרלות -</Text>
            <Flex className={classes.wrapper}>
                {formType !== ChanceFormTypes.RAV_CHANCE &&
                <SelectField width={'100%'} label={false} fontWeight={'bold'} onChange={onChangeRowNum}
                             defaultValue={'' + formSize} style={{borderColor: '#40BC4C'}}>
                    {formMaxSizeOptions}
                </SelectField>}
                <SelectField width={'100%'} label={false} fontWeight={'bold'} onChange={onChangeParticipantSum}
                             defaultValue={'' + formSize} style={{borderColor: '#40BC4C'}}>
                    {participantOptions}
                </SelectField>
                <SelectField
                    width={'100%'}
                    label={false}
                    fontWeight={'bold'}
                    value={multiLottery.toString()}
                    onChange={(e) => dispatch(setMultiLottery(Number(e.target.value)))}
                    style={{borderColor: '#40BC4C'}}
                >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="8">8</option>
                </SelectField>
            </Flex>
            <Button onClick={() => automaticFill()} backgroundColor={'#1E1E1E'} isFullWidth>מלא
                לוטומטיק</Button>
            <Text>*בחרו קלף בכל שורה -</Text>
            <ScrollView ref={props.elRef} width="100%" height={450}>
                {
                    Array.from(Array(formSize)).map((_, index) =>
                        <ChanceRow key={index} index={index}/>
                    )
                }
            </ScrollView>
        </Flex>
    )
}
